import { ReactNode, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { Loader } from '../common/components/Loader'
import { useAuth } from '../contexts/auth-provider'
import Login from '../pages/auth/Login'

export function AuthGuard({ children, adminOnly = false }: { children: ReactNode; adminOnly?: boolean }) {
  const { isAuthenticated, isInitialized, user } = useAuth()

  const { pathname } = useLocation()

  const [requestedLocation, setRequestedLocation] = useState<string | null>(null)

  if (!isInitialized) {
    return <Loader />
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname)
    }
    return <Login />
  }

  if (isAuthenticated && adminOnly && user?.role !== 'admin') {
    return <Navigate to="/404" />
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null)
    return <Navigate to={requestedLocation} />
  }

  return <>{children}</>
}
