export enum Role {
  admin = 'admin',
  owner = 'owner',
  user = 'user',
}

export enum Status {
  active = 'active',
  inactive = 'inactive',
}

export enum UserConfirmationStatus {
  pending = 'pending',
  approved = 'approved',
}

export interface UserConfirmToken {
  id: string
  userId: string
  token: string
  expiresAt?: Date
  createdAt?: Date
  consumedAt?: Date
}

export interface User {
  id: string
  accountId: string
  username: string
  firstName: string
  lastName: string
  email: string
  confirmed: boolean
  role: Role
  status: Status
  createdAt?: Date
  updatedAt?: Date
  confirmTokens: UserConfirmToken[]
}

export type CreateUser = Partial<
  Pick<User, 'accountId' | 'username' | 'firstName' | 'lastName' | 'email' | 'role' | 'confirmed' | 'status'>
>
