import { createContext, useContext, useState } from 'react'


export type YearlySpreadType = 'quarterly' | 'monthly'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DisplayOptionsContext = createContext<ReturnType<typeof useInit>>(null as any)

const useInit = () => {
  const [yearlySpreadsType, setYearlySpreadsType] = useState<YearlySpreadType>('quarterly')

  return {
    yearlySpreadsType,
    setYearlySpreadsType,
  }
}

export function DisplayOptionsProvider({ children }: { children?: React.ReactNode }) {
  return <DisplayOptionsContext.Provider value={useInit()}>{children}</DisplayOptionsContext.Provider>
}

export const useDisplayOptions = () => {
  return useContext(DisplayOptionsContext)
}
